"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Color = void 0;
var number_util_1 = require("../../../common/number-util");
var type_util_1 = require("../../../misc/type-util");
var color_model_1 = require("./color-model");
var CONSTRAINT_MAP = {
    hsl: function (comps) {
        var _a;
        return [
            number_util_1.loopRange(comps[0], 360),
            number_util_1.constrainRange(comps[1], 0, 100),
            number_util_1.constrainRange(comps[2], 0, 100),
            number_util_1.constrainRange((_a = comps[3]) !== null && _a !== void 0 ? _a : 1, 0, 1),
        ];
    },
    hsv: function (comps) {
        var _a;
        return [
            number_util_1.loopRange(comps[0], 360),
            number_util_1.constrainRange(comps[1], 0, 100),
            number_util_1.constrainRange(comps[2], 0, 100),
            number_util_1.constrainRange((_a = comps[3]) !== null && _a !== void 0 ? _a : 1, 0, 1),
        ];
    },
    rgb: function (comps) {
        var _a;
        return [
            number_util_1.constrainRange(comps[0], 0, 255),
            number_util_1.constrainRange(comps[1], 0, 255),
            number_util_1.constrainRange(comps[2], 0, 255),
            number_util_1.constrainRange((_a = comps[3]) !== null && _a !== void 0 ? _a : 1, 0, 1),
        ];
    },
};
function isRgbColorComponent(obj, key) {
    if (typeof obj !== 'object' || type_util_1.isEmpty(obj)) {
        return false;
    }
    return key in obj && typeof obj[key] === 'number';
}
/**
 * @hidden
 */
var Color = /** @class */ (function () {
    function Color(comps, mode) {
        this.mode_ = mode;
        this.comps_ = CONSTRAINT_MAP[mode](comps);
    }
    Color.black = function () {
        return new Color([0, 0, 0], 'rgb');
    };
    Color.fromObject = function (obj) {
        var comps = 'a' in obj ? [obj.r, obj.g, obj.b, obj.a] : [obj.r, obj.g, obj.b];
        return new Color(comps, 'rgb');
    };
    Color.toRgbaObject = function (color) {
        return color.toRgbaObject();
    };
    Color.isRgbColorObject = function (obj) {
        return (isRgbColorComponent(obj, 'r') &&
            isRgbColorComponent(obj, 'g') &&
            isRgbColorComponent(obj, 'b'));
    };
    Color.isRgbaColorObject = function (obj) {
        return this.isRgbColorObject(obj) && isRgbColorComponent(obj, 'a');
    };
    Color.isColorObject = function (obj) {
        return this.isRgbColorObject(obj);
    };
    Color.equals = function (v1, v2) {
        if (v1.mode_ !== v2.mode_) {
            return false;
        }
        var comps1 = v1.comps_;
        var comps2 = v2.comps_;
        for (var i = 0; i < comps1.length; i++) {
            if (comps1[i] !== comps2[i]) {
                return false;
            }
        }
        return true;
    };
    Object.defineProperty(Color.prototype, "mode", {
        get: function () {
            return this.mode_;
        },
        enumerable: false,
        configurable: true
    });
    Color.prototype.getComponents = function (opt_mode) {
        return color_model_1.appendAlphaComponent(color_model_1.convertColorMode(color_model_1.removeAlphaComponent(this.comps_), this.mode_, opt_mode || this.mode_), this.comps_[3]);
    };
    Color.prototype.toRgbaObject = function () {
        var rgbComps = this.getComponents('rgb');
        return {
            r: rgbComps[0],
            g: rgbComps[1],
            b: rgbComps[2],
            a: rgbComps[3],
        };
    };
    return Color;
}());
exports.Color = Color;
