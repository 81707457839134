"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberColorInputPlugin = void 0;
var color_1 = require("./controller/color");
var color_number_1 = require("./converter/color-number");
var color_string_1 = require("./converter/color-string");
var writer_1 = require("./converter/writer");
var color_2 = require("./model/color");
var util_1 = require("./util");
function shouldSupportAlpha(inputParams) {
    return 'alpha' in inputParams && inputParams.alpha === true;
}
function createFormatter(supportsAlpha) {
    return supportsAlpha
        ? function (v) { return color_string_1.colorToHexRgbaString(v, '0x'); }
        : function (v) { return color_string_1.colorToHexRgbString(v, '0x'); };
}
/**
 * @hidden
 */
exports.NumberColorInputPlugin = {
    id: 'input-color-number',
    type: 'input',
    accept: function (value, params) {
        if (typeof value !== 'number') {
            return null;
        }
        if (!('view' in params)) {
            return null;
        }
        if (params.view !== 'color') {
            return null;
        }
        var result = util_1.parseColorInputParams(params);
        return result
            ? {
                initialValue: value,
                params: result,
            }
            : null;
    },
    binding: {
        reader: function (args) {
            return shouldSupportAlpha(args.params)
                ? color_number_1.colorFromRgbaNumber
                : color_number_1.colorFromRgbNumber;
        },
        equals: color_2.Color.equals,
        writer: function (args) {
            return writer_1.createColorNumberWriter(shouldSupportAlpha(args.params));
        },
    },
    controller: function (args) {
        var supportsAlpha = shouldSupportAlpha(args.params);
        var expanded = 'expanded' in args.params ? args.params.expanded : undefined;
        var picker = 'picker' in args.params ? args.params.picker : undefined;
        return new color_1.ColorController(args.document, {
            expanded: expanded !== null && expanded !== void 0 ? expanded : false,
            formatter: createFormatter(supportsAlpha),
            parser: color_string_1.CompositeColorParser,
            pickerLayout: picker !== null && picker !== void 0 ? picker : 'popup',
            supportsAlpha: supportsAlpha,
            value: args.value,
            viewProps: args.viewProps,
        });
    },
};
