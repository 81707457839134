import {Path, Point, Color, view, setup, Raster, Size, Group, project, Shape} from "paper";
import {BladeApi, ButtonApi, InputBindingApi, Pane} from 'tweakpane';
import {clone, contain, cover, debounce_leading} from "../utils/Helpers";
import {BladeController, View} from "@tweakpane/core";
import * as ImagePlugin from '../tweakpane-image-plugin-main/src/index';

require('../assets/static/webgl-image-filter.js');

declare var WebGLImageFilter: any;


export class CanvasRenderer {
    private paneWrapper = document.querySelector('.pane') as HTMLDivElement;
    private panePages = Array.from(document.querySelectorAll('.pane-page'));
    private pane = new Pane({
        container: document.querySelector('.pane-controls-inject.second-pane')
    });
    private paneFirstScreen = new Pane({
        container: document.querySelector('.pane-controls-inject.first-pane')
    });
    private sourceImage = document.getElementById('sourceImage') as HTMLImageElement;
    private sourceCanvas = document.getElementById('sourceCanvas') as HTMLCanvasElement;
    private mainCanvas = document.getElementById("MainCanvas") as HTMLCanvasElement;
    private mainCanvasWrapper = document.querySelector('.MainCanvasWrapper') as HTMLCanvasElement;
    private filterContext: WebGLRenderingContext;
    private PARAMS_DEFAULT = {};
    private PARAMS = {
        brightness: 0,
        contrast: 0,
        style: 0,
        threshold: 0,
        scaleWidth: 8,
        scaleHeight: 10,
        DPI: 5,
        clones: 60,
        scaleFactor: 1.5,
        rings: 150,
        resolutionIndependent: true,
        resolutionIndependentDefaultValue: 700,
        invert: true,
        addText: true,
        useScale: true,
        spin: false,
        rotateRelativeToCenterPoint: true,
        scaleByColor: true,
        centerOffset: new Point(-100, -26),
        image: new Image(),
        renderDimensions: new Point(600, 600)
    };
    private copyCanvas = document.createElement("canvas");
    private copyCanvasContext = this.copyCanvas.getContext('2d');
    private filter: any;

    private paperRaster: Raster;
    // private rasterPathItems: Path[] = [];
    private ready: boolean = false;
    private backgroundRectangle: Shape.Rectangle;
    private group: Group;
    private overlayGrup: Group;
    private statusText: BladeApi<BladeController<View>>;
    private svgButton: ButtonApi;
    private svgSymbol: any;

    private GRID = false;
    private imageInput: InputBindingApi<unknown, HTMLImageElement>;
    private svgOverlaySymbol: any;
    private svgOverlaySymbolParent: any;
    private timeout: any = 0;

    private pixelColorCache = [];

    constructor() {
        setup(this.mainCanvas)
        this.paperRaster = new Raster();
        this.paperRaster.visible = false;


        // this.copyCanvas.classList.add('copycanvas');
        // document.documentElement.append(this.copyCanvas)
        this.setupFilter();

        this.setupTweakPane();

        this.resize();

        this.svgSymbol = project.importSVG(document.getElementById('SVGSymbol'), {
            insert: false,
            expandShapes: false
        }).children[1];
        this.svgSymbol.fillColor = '#fc4800'


        this.svgOverlaySymbolParent = project.importSVG(document.getElementById('SVGOverlay'), {
            insert: false,
            expandShapes: false
        })
        this.svgOverlaySymbol = this.svgOverlaySymbolParent.children[1].children[0];


        let clone = this.svgOverlaySymbol.clone({insert: false, deep: true});
        // console.log(clone.bounds);
        const upscaleFactor = 2 / window.devicePixelRatio;
//        console.log(upscaleFactor);
        this.svgOverlaySymbol.scale(2 * upscaleFactor);
        this.svgOverlaySymbol.position.x = this.PARAMS.renderDimensions.x * 1.5 * upscaleFactor;
        this.svgOverlaySymbol.position.y = this.PARAMS.renderDimensions.y * 1.5 * upscaleFactor;
        this.overlayGrup = new Group();
        this.overlayGrup.addChild(clone);
        this.overlayGrup.pivot = new Point(0, 0);

        window.addEventListener('resize', this.resize);
        if (this.sourceImage.src) {
            if (this.sourceImage.complete) {
                this.imageReady();
                console.log('image loaded before init');
            }
        }
        this.sourceImage.onload = () => {
            this.imageReady();
            console.log('image loaded');
        }
        // }
        document.querySelector('.pane-BackButton').addEventListener('click', this.gotoStep1);
    }

    private currState = 0;

    private gotoStep2 = () => {
        console.log('gotoStep2', this.currState)
        if (this.currState !== 1) {
            this.currState = 1;
            this.paneWrapper.style.height = this.panePages[this.currState].clientHeight + 'px';
            document.documentElement.classList.add('step-2');
        }
    }

    private gotoStep1 = (event: MouseEvent = null) => {
        if (event) {
            event.preventDefault();
        }
        console.log('gotoStep1', this.currState)
        if (this.currState !== 0) {
            this.currState = 0;
            this.paneWrapper.style.height = this.panePages[this.currState].clientHeight + 'px';
            document.documentElement.classList.remove('step-2');
        }
        return false;
    }

    private imageReady = () => {
        document.documentElement.classList.add('processing');
        this.pixelColorCache = [];
        this.gotoStep2();
        this.sourceImage.width = this.sourceImage.naturalWidth;
        this.sourceImage.height = this.sourceImage.naturalHeight;
        this.resize();
        this.updateFilter(true, false, false, true);
    }
    private imageChanged = (ev) => {
        this.ready = false;
        this.pixelColorCache = [];
        if (this.sourceImage.src === ev.value.src) {
            this.gotoStep2();
        }
        this.sourceImage.src = ev.value.src;
        // this.imageReady();
        // console.log(ev.value, ev);
    }

    private updateFilterOnLast = (ev, instant = true, skipWebGl = true) => {
        // console.log(ev);
        if (ev.last) {
            this.pixelColorCache = [];
            this.updateFilter(instant, skipWebGl)
        }
    }
    private updateWebGLFilter = (ev) => {
        // console.log(ev);
        if (ev.last) {
            this.pixelColorCache = [];
            this.updateFilter(true, false)
        } else {
            // this.updateFilter(true, false, true)

        }
    }

    setupTweakPane() {
        this.pane.registerPlugin(ImagePlugin);

        //Save defaults:
        Object.assign(this.PARAMS_DEFAULT, this.PARAMS);


        this.pane.addInput(
            this.PARAMS, 'brightness',
            {min: -0.5, max: 0.5, step: 0.001, label: 'Brightness'}
        ).on('change', (ev) => this.updateWebGLFilter(ev));
        this.pane.addInput(
            this.PARAMS, 'contrast',
            {min: -0.5, max: 0.5, step: 0.001, label: 'Contrast'}
        ).on('change', (ev) => this.updateWebGLFilter(ev));
        /*        this.pane.addInput(
                    this.PARAMS, 'threshold',
                    {min: 0, max: 1, step: 0.01}
                ).on('change', (ev) => this.updateFilterOnLast(ev,true, true));*/
        this.pane.addInput(
            this.PARAMS, 'resolutionIndependentDefaultValue',
            {min: 450, max: 900, step: 1, label: 'Detail'}
        ).on('change', (ev) => this.updateFilterOnLast(ev, false, true));
        this.pane.addInput(
            this.PARAMS, 'scaleFactor',
            {min: 1, max: 1.7, step: 0.1, label: 'Spike size'}
        ).on('change', (ev) => this.updateFilterOnLast(ev, false, true));


        // this.pane.addSeparator();
        const styleFolder = this.pane.addFolder({
            title: 'Presets',
        });
        styleFolder.addButton({
            title: ''
        }).on('click', () => this.setStyle(0))
        styleFolder.addButton({
            title: ''
        }).on('click', () => this.setStyle(1))
        styleFolder.addButton({
            title: ''
        }).on('click', () => this.setStyle(2))
        styleFolder.addButton({
            title: ''
        }).on('click', () => this.setStyle(3))

        const booleanFolder = this.pane.addFolder({
            title: 'Basic',
        });
        booleanFolder.addInput(
            this.PARAMS, 'invert', {label: 'Invert'},
        ).on('change', (ev) => {
            this.updateFilterOnLast(ev, true, true);
        });
        booleanFolder.addInput(
            this.PARAMS, 'addText', {label: 'Add text'},
        ).on('change', () => {
            this.overlayGrup.visible = this.PARAMS.addText;
        });

        /*        this.pane.addSeparator();
        this.pane.addInput(
            this.PARAMS, 'DPI',
            {min: 1, max: 20, step: 1}
        ).on('change', (ev) => this.updateFilterOnLast(ev,false, true));*/
        /*        this.pane.addInput(
                    this.PARAMS, 'clones',
                    {min: 1, max: 200, step: 1}
                ).on('change', (ev) => this.updateFilterOnLast(ev,false, true));
                this.pane.addInput(
                    this.PARAMS, 'rings',
                    {min: 1, max: 200, step: 1}
                ).on('change', (ev) => this.updateFilterOnLast(ev,false, true));
                this.pane.addSeparator();*/
        /*        this.pane.addInput(
                    this.PARAMS, 'scaleWidth',
                    {min: 0.1, max: 10, step: 0.01}
                ).on('change', (ev) => this.updateFilterOnLast(ev,false, true));
                this.pane.addInput(
                    this.PARAMS, 'scaleHeight',
                    {min: 0.1, max: 10, step: 0.01}
                ).on('change', (ev) => this.updateFilterOnLast(ev,false, true));
                this.pane.addInput(
                    this.PARAMS, 'scaleFactor',
                    {min: 0.1, max: 10, step: 0.1}
                ).on('change', (ev) => this.updateFilterOnLast(ev,false, true));
                this.pane.addInput(
                    this.PARAMS, 'useScale',
                ).on('change', (ev) => this.updateFilterOnLast(ev,true, true));
                this.pane.addInput(
                    this.PARAMS, 'scaleByColor',
                ).on('change', (ev) => this.updateFilterOnLast(ev,true, true));
                this.pane.addSeparator();

                this.pane.addSeparator();*/

        /*        this.pane.addInput(
                    this.PARAMS, 'resolutionIndependent',
                ).on('change', (ev) => this.updateFilterOnLast(ev,true, true));
                this.pane.addInput(
                    this.PARAMS, 'resolutionIndependentDefaultValue',
                    {min: 1, max: 4096, step: 1}
                ).on('change', (ev) => this.updateFilterOnLast(ev,false, true));*/

        // this.pane.addSeparator();
        this.pane.addInput(this.PARAMS, 'centerOffset', {
            picker: 'inline',
            expanded: true,
            label: 'Spike rotation',
            x: {step: 1, min: -100, max: 100},
            y: {step: 1, min: -100, max: 100}
        }).on('change', (ev) => this.updateFilterOnLast(ev, false, true));
        /*        this.pane.addSeparator();
                this.pane.addInput(
                    this.PARAMS, 'rotateRelativeToCenterPoint',
                ).on('change', (ev) => this.updateFilterOnLast(ev,true, true));
                this.pane.addInput(
                    this.PARAMS, 'spin',
                ).on('change', (ev) => this.updateFilterOnLast(ev,true, true));*/

        // this.pane.addSeparator();

        this.imageInput = this.pane.addInput(this.PARAMS, 'image', {
            extensions: '.jpg, .gif, .png, .webp',
            label: 'Your image'
        }).on('change', this.imageChanged);

        const buttonFolder1 = this.pane.addFolder({
            title: 'Buttons',
        });
        buttonFolder1.addButton({
            title: 'Choose new image'
        }).on('click', this.triggerImageUploadClick)
        buttonFolder1.addButton({
            title: 'Reset'
        }).on('click', this.reset)
        // this.pane.addSeparator();
        const buttonFolder2 = this.pane.addFolder({
            title: 'Buttons 2',
        });
        buttonFolder2.addButton({
            title: 'Save as Image'
        }).on('click', this.saveImage)

        this.svgButton = buttonFolder2.addButton({
            title: 'Save as SVG'
        });
        this.svgButton.on('click', this.saveSVG);

        this.paneFirstScreen.addButton({
            title: 'Choose Image'
        }).on('click', this.firstPaneImageUploadClick)
        this.pane.element.classList.add('active-button-style-' + this.PARAMS.style);
    }


    private firstPaneImageUploadClick = (event) => {
        this.gotoStep2();
        // @ts-ignore
        this.pane.element.querySelector('.tp-imgv_input').click();
    }

    private triggerImageUploadClick = (event) => {
        // @ts-ignore
        this.pane.element.querySelector('.tp-imgv_input').click();
    }

    setupFilter() {
        // Example:
        try {
            this.filter = new WebGLImageFilter({canvas: this.sourceCanvas});
            this.filterContext = this.filter.getContext();
        } catch (err) {
            console.log(err);
            // Handle browsers that don't support WebGL
        }
    }


    private timeoutRef:any;
    private updateFilter = (instant = false, skipWebgl = false, webglOnly = false, imageReadyMode = false) => {
        // filter.addFilter('desaturate');
        // if (skipWebgl === false) {
        document.documentElement.classList.add('processing');
        clearTimeout(this.timeoutRef);
        this.timeoutRef = setTimeout(() => {
            this.filter.reset();
            this.filter.addFilter('desaturateLuminance');
            this.filter.addFilter('contrast', this.PARAMS.contrast);
            this.filter.addFilter('brightness', this.PARAMS.brightness);
            if (this.PARAMS.invert) {
                this.filter.addFilter('negative');
            }

            // inputImage may be an Image, or even an HTML Canvas!
            var filteredImage = this.filter.apply(this.sourceImage);

            // The 'filteredImage' is a canvas element. You can draw it on a 2D canvas
            // or just add it to your DOM

            // Use .reset() to clear the current filter chain. This is faster than creating a new
            // WebGLImageFilter instance
            // }
            if (webglOnly === true) {
                return;
            }
            this.resize();


            // clearTimeout(this.timeout);
            // this.timeout = setTimeout(() => {
            if (instant) {
                this.updateRasterImmediately();
            } else {
                this.updateRaster();
            }
            if (imageReadyMode) {
                this.ready = true;
                this.resize();
            }
        }, 200);
        // }, 200)
    }

    clearRaster = () => {
        // this.rasterPathItems = [];
        if (this.group) {
            // this.group.children.forEach(path => path.remove());
            this.group.remove();
            this.group = null;
        }
        this.group = new Group();
        this.overlayGrup.bringToFront();
    }

    updateRaster = () => {
        this.updateRasterImmediately();
        // });
    }


    private updateRasterImmediately = () => {
        console.log('updateRasterImmediately');

        performance.mark('updateRasterImmediately');
        performance.measure('updateRasterImmediately');
        console.time("updateRasterImmediately");
        // var dst = raster.context.createImageData(size.width, size.height);
        this.clearRaster();
        var scaleFactor = (this.PARAMS.resolutionIndependent ? this.PARAMS.resolutionIndependentDefaultValue / this.PARAMS.renderDimensions.x : 1) / this.PARAMS.scaleFactor;

        let bgColor = "#3A0084";
        let fillColor = "#fc4800";
        let textColor = "#ffffff";
        if (this.PARAMS.style === 1) {
            bgColor = "#fc4800";
            fillColor = "#3A0084";
        } else if (this.PARAMS.style === 2) {
            bgColor = "#ffffff";
            fillColor = "#fc4800";
            textColor = "#3A0084";
        } else if (this.PARAMS.style === 3) {
            bgColor = "#ffffff";
            fillColor = "#3A0084";
            textColor = "#fc4800";
        }

        this.svgOverlaySymbolParent.fillColor = new Color(textColor);
        this.overlayGrup.children.forEach((child) => child.remove())
        let cloneOverlay = this.svgOverlaySymbolParent.children[1].children[0].clone({insert: false, deep: true});
        this.overlayGrup.addChild(cloneOverlay);

        this.svgSymbol.fillColor = new Color(fillColor);
        this.backgroundRectangle = new Shape.Rectangle(new Point(0, 0), new Size(this.PARAMS.renderDimensions.x * scaleFactor, this.PARAMS.renderDimensions.y * scaleFactor));
        this.backgroundRectangle.fillColor = new Color(bgColor);
        this.group.addChild(this.backgroundRectangle);

        this.paperRaster.setSize(this.PARAMS.renderDimensions.x, this.PARAMS.renderDimensions.y);
        this.copyCanvasContext.clearRect(0, 0, this.copyCanvas.width, this.copyCanvas.height);

        const {
            offsetX,
            offsetY,
            width,
            height
        } = cover(this.PARAMS.renderDimensions.x, this.PARAMS.renderDimensions.y, this.sourceCanvas.width, this.sourceCanvas.height)
        // console.log(offsetX, offsetY, width, height, this.sourceCanvas.width, this.sourceCanvas.height);
        this.copyCanvasContext.drawImage(this.sourceCanvas, offsetX, offsetY, width, height)

        // this.copyCanvasContext.drawImage(this.sourceCanvas, 0, 0);


        this.paperRaster.setImageData(this.copyCanvasContext.getImageData(0, 0, this.copyCanvas.width, this.copyCanvas.height));


        // Hide the raster:

        // The size of our grid cells:
        var gridSize = this.PARAMS.DPI;

        // Space the cells by 120%:
        var spacing = 1;
        // As the web is asynchronous, we need to wait for the raster to load
        // before we can perform any operation on its pixels.
        // raster.on('load', () => {
        // Since the example image we're using is much too large,
        // and therefore has way too many pixels, lets downsize it to
        // 40 pixels wide and 30 pixels high:


        // this.rasterPathItems = [];
        this.paperRaster.size = new Size((this.PARAMS.renderDimensions.x * scaleFactor) / this.PARAMS.DPI, (this.PARAMS.renderDimensions.y * scaleFactor) / this.PARAMS.DPI);
        // console.log(this.paperRaster.size);

        var halfWidth = this.paperRaster.width / 2 * this.PARAMS.DPI;
        var halfHeight = this.paperRaster.height / 2 * this.PARAMS.DPI;
        var offset = new Point(this.PARAMS.centerOffset.x / 100 * halfWidth, this.PARAMS.centerOffset.y / 100 * halfHeight)
        var centerPoint = new Point(halfWidth + (this.PARAMS.centerOffset.x / 100 * halfWidth), halfHeight + (this.PARAMS.centerOffset.y / 100 * halfHeight))
        console.time("updateRasterImmediatelyLoop");

        // console.log(this.paperRaster);

        if (this.GRID) {
            for (var y = 0; y < this.paperRaster.height; y++) {
                for (var x = 0; x < this.paperRaster.width; x++) {
                    // Get the color of the pixel:
                    var color = this.paperRaster.getPixel(x, y);
                    if (color.alpha > 0 && color.gray > this.PARAMS.threshold) {
                        // Create a circle shaped path:
                        var path = this.svgSymbol.clone({insert: true, deep: true});
                        var ratio = path.bounds.size.height / path.bounds.size.width;
                        path.bounds.size.width = gridSize / 2 / spacing;
                        path.bounds.size.height = path.bounds.size.width * ratio;
                        path.scale(this.PARAMS.scaleWidth, this.PARAMS.scaleHeight);
                        path.position = new Point(x * gridSize + /*gridSize * 0.5 +*/ path.bounds.size.width * 0.5, y * gridSize + gridSize * 0.5);
                        /*                    var path = new Path.Circle({
                                                center: new Point(x * gridSize + gridSize * 0.5, y * gridSize + gridSize * 0.5),
                                                radius: gridSize / 2 / spacing,
                                                fillColor: '#fc4800'
                                            });*/
                        // Scale the path by the amount of gray in the pixel color:
                        if (this.PARAMS.scaleByColor == true) {
                            path.scale(1 - color.gray);
                        }
                        this.group.addChild(path);
                        // this.rasterPathItems.push(path);
                    }
                }
            }
        } else {
            //Clones should be dependant on diameter.
            let rings = this.PARAMS.rings;
            let ringDistance = (this.PARAMS.renderDimensions.x * scaleFactor) / this.PARAMS.rings;
            let radiusStart = 10;
            let radius = radiusStart;
            // let centerOffset = new Point(-300, 0);
            let ratio = this.svgSymbol.bounds.size.height / this.svgSymbol.bounds.size.width;
            let vectorOuter = new Point();
            let pixelX = 0;
            let pixelY = 0;
            let pixelColorCacheIndex = '';
            let color;
            for (let j = 0; j < rings; j++) {
                let clones = Math.floor(radius / ringDistance * this.PARAMS.clones / 10);//Math.floor(this.PARAMS.clones);
                let angle = 360 / clones;
                for (let i = 0, l = clones/* - 1*/; i < l; i++) {
                    // vectorOuter.set(0,0);
                    //Rotate on radial:
                    let position = vectorOuter.rotate(angle * i, [radius, radius]);

                    //Offset from center:
                    position.x += halfWidth - radius + offset.x;
                    position.y += halfHeight - radius + offset.y;
                    // console.log(position.getAngle(offset));

                    pixelX = position.x / this.PARAMS.DPI;
                    pixelY = position.y / this.PARAMS.DPI;
                    if (pixelX >= 0 && pixelY >= 0 && pixelX <= this.paperRaster.width && pixelY <= this.paperRaster.height) { //Skip points outside source image:
                        pixelColorCacheIndex = Math.floor(pixelX) + ',' + Math.floor(pixelY);

                        if (this.pixelColorCache[pixelColorCacheIndex]) {
                            color = this.pixelColorCache[pixelColorCacheIndex];
                        } else {
                            color = this.paperRaster.getPixel(pixelX, pixelY); // around 250ms, could maybe be cached?
                            this.pixelColorCache[pixelColorCacheIndex] = color;
                        }
                        // console.log(color);
                        if (color.alpha > 0 && color.gray > this.PARAMS.threshold) {
                            // Create a circle shaped path:

                            let path = this.svgSymbol.clone({insert: false, deep: false});

                            path.bounds.size.width = this.PARAMS.DPI / 4;
                            path.bounds.size.height = path.bounds.size.width * ratio;

                            if (this.PARAMS.useScale) {
                                path.scale(this.PARAMS.scaleWidth, this.PARAMS.scaleHeight);
                            }

                            path.position = position;
                            if (this.PARAMS.spin) {
                                path.rotate(angle * i);
                            }
                            if (this.PARAMS.rotateRelativeToCenterPoint) {
                                // console.log(vector.angle)
                                path.rotate(path.position.subtract(centerPoint).angle);
                            }
                            if (this.PARAMS.scaleByColor == true) {
                                // Scale the path by the amount of gray in the pixel color:
                                path.scale(1 - color.gray);
                            }

                            this.group.addChild(path);
                            // this.rasterPathItems.push(path);
                        }
                    }
                }
                radius += ringDistance;
            }
        }
        console.timeEnd('updateRasterImmediatelyLoop');

        // Move the active layer to the center of the view, so all
        // the created paths in it appear centered.
        view.draw();
        this.group.pivot = new Point(0, 0);
        // this.group.fitBounds(view.bounds);
        // project.activeLayer.position = view.center;
        this.onViewResize();
        document.documentElement.classList.remove('processing');
        performance.mark('updateRasterImmediately');
        performance.measure('updateRasterImmediately');
        console.timeEnd('updateRasterImmediately');
    }

    private saveImage = () => {
        // Create a blob from the canvas...
        view.element.toBlob((blob) => {
            // ...and get it as a URL.
            const url = URL.createObjectURL(blob);
            // Open it in a new tab.
            window.open(url, '_blank');
        });
    }
    private saveSVG = () => {
        // Create a blob from the canvas...
        var fileName = "ifpa.svg"
        var url = "data:image/svg+xml;utf8," + encodeURIComponent(project.exportSVG({asString: true, embedImages: false}));
        var link = document.createElement("a");
        link.download = fileName;
        link.href = url;
        link.click();
    }

    private onViewResize = () => {
        /*        this.group.fitBounds(view.bounds);
                project.activeLayer.position = view.center;*/

        // console.log(view.bounds.width / this.backgroundRectangle.bounds.width);
        this.group.scale(view.bounds.width / this.backgroundRectangle.bounds.width);
    }
    private resize = () => {
        // console.log(this.sourceImage.parentElement.clientWidth, this.sourceImage.naturalWidth);
        // this.sourceImage.style.width = this.sourceImage.parentElement.clientWidth / this.sourceImage.naturalWidth + 'px';
        // this.sourceImage.style.height = this.sourceImage.naturalHeight;
        // console.log('resize window');
        this.copyCanvas.width = this.PARAMS.renderDimensions.x;
        this.copyCanvas.height = this.PARAMS.renderDimensions.y;
        if (this.ready) {
            var height = this.copyCanvas.height / this.copyCanvas.width * this.mainCanvasWrapper.clientWidth;
            this.mainCanvasWrapper.style.height = height + 'px';
            // console.log(this.mainCanvasWrapper.clientWidth, this.mainCanvasWrapper.clientHeight)
            // view.viewSize.width = this.mainCanvasWrapper.clientWidth;
            // view.viewSize.height = height;
            view.viewSize.height = this.PARAMS.renderDimensions.x * 4 / view.pixelRatio;
            view.viewSize.width = this.PARAMS.renderDimensions.y * 4 / view.pixelRatio;
            // view.viewSize.width = this.sourceCanvas.width;
            // view.viewSize.height = this.sourceCanvas.height;

            this.mainCanvas.style.width = this.mainCanvasWrapper.style.height;
            this.mainCanvas.style.height = this.mainCanvasWrapper.style.height;
            var center = new Point(view.size.width / 2, view.size.height / 2)
            view.center = center;
            this.onViewResize();
        }
        this.paneWrapper.style.height = this.panePages[this.currState].clientHeight + 'px';

    }

    private reset = () => {
        for (let key in this.PARAMS) {
            if (key != 'image') {
                this.PARAMS[key] = this.PARAMS_DEFAULT[key];
            }
        }
        this.PARAMS.centerOffset = new Point(-100, -26);
        this.pane.refresh();
        // this.updateFilter(true);
    }

    private setStyle = (number: number) => {
        if (this.PARAMS.style !== number) {
            this.pane.element.classList.remove('active-button-style-' + this.PARAMS.style);
            this.PARAMS.style = number;
            this.PARAMS.invert = number === 0 ? true : false;
            this.pixelColorCache = [];
            this.pane.refresh();
            this.pane.element.classList.add('active-button-style-' + this.PARAMS.style);
            // this.updateFilter(true, true)
        }
    }
}