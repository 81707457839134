"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NumberInputPlugin = exports.createRangeConstraint = exports.createStepConstraint = void 0;
var composite_1 = require("../../common/constraint/composite");
var list_1 = require("../../common/constraint/list");
var range_1 = require("../../common/constraint/range");
var step_1 = require("../../common/constraint/step");
var list_2 = require("../../common/controller/list");
var number_1 = require("../../common/converter/number");
var value_map_1 = require("../../common/model/value-map");
var number_text_1 = require("../../common/number/controller/number-text");
var slider_text_1 = require("../../common/number/controller/slider-text");
var params_parsers_1 = require("../../common/params-parsers");
var primitive_1 = require("../../common/primitive");
var util_1 = require("../../common/util");
var type_util_1 = require("../../misc/type-util");
/**
 * Tries to create a step constraint.
 * @param params The input parameters object.
 * @return A constraint or null if not found.
 */
function createStepConstraint(params) {
    if ('step' in params && !type_util_1.isEmpty(params.step)) {
        return new step_1.StepConstraint(params.step);
    }
    return null;
}
exports.createStepConstraint = createStepConstraint;
/**
 * Tries to create a range constraint.
 * @param params The input parameters object.
 * @return A constraint or null if not found.
 */
function createRangeConstraint(params) {
    if (('max' in params && !type_util_1.isEmpty(params.max)) ||
        ('min' in params && !type_util_1.isEmpty(params.min))) {
        return new range_1.RangeConstraint({
            max: params.max,
            min: params.min,
        });
    }
    return null;
}
exports.createRangeConstraint = createRangeConstraint;
function createConstraint(params) {
    var constraints = [];
    var sc = createStepConstraint(params);
    if (sc) {
        constraints.push(sc);
    }
    var rc = createRangeConstraint(params);
    if (rc) {
        constraints.push(rc);
    }
    var lc = util_1.createListConstraint(params.options);
    if (lc) {
        constraints.push(lc);
    }
    return new composite_1.CompositeConstraint(constraints);
}
function findRange(constraint) {
    var c = constraint ? composite_1.findConstraint(constraint, range_1.RangeConstraint) : null;
    if (!c) {
        return [undefined, undefined];
    }
    return [c.minValue, c.maxValue];
}
function estimateSuitableRange(constraint) {
    var _a = findRange(constraint), min = _a[0], max = _a[1];
    return [min !== null && min !== void 0 ? min : 0, max !== null && max !== void 0 ? max : 100];
}
/**
 * @hidden
 */
exports.NumberInputPlugin = {
    id: 'input-number',
    type: 'input',
    accept: function (value, params) {
        if (typeof value !== 'number') {
            return null;
        }
        var p = params_parsers_1.ParamsParsers;
        var result = params_parsers_1.parseParams(params, {
            format: p.optional.function,
            max: p.optional.number,
            min: p.optional.number,
            options: p.optional.custom(util_1.parseListOptions),
            step: p.optional.number,
        });
        return result
            ? {
                initialValue: value,
                params: result,
            }
            : null;
    },
    binding: {
        reader: function (_args) { return number_1.numberFromUnknown; },
        constraint: function (args) { return createConstraint(args.params); },
        writer: function (_args) { return primitive_1.writePrimitive; },
    },
    controller: function (args) {
        var _a, _b;
        var value = args.value;
        var c = args.constraint;
        if (c && composite_1.findConstraint(c, list_1.ListConstraint)) {
            return new list_2.ListController(args.document, {
                props: value_map_1.ValueMap.fromObject({
                    options: (_a = util_1.findListItems(c)) !== null && _a !== void 0 ? _a : [],
                }),
                value: value,
                viewProps: args.viewProps,
            });
        }
        var formatter = (_b = ('format' in args.params ? args.params.format : undefined)) !== null && _b !== void 0 ? _b : number_1.createNumberFormatter(util_1.getSuitableDecimalDigits(c, value.rawValue));
        if (c && composite_1.findConstraint(c, range_1.RangeConstraint)) {
            var _c = estimateSuitableRange(c), min = _c[0], max = _c[1];
            return new slider_text_1.SliderTextController(args.document, {
                baseStep: util_1.getBaseStep(c),
                parser: number_1.parseNumber,
                sliderProps: value_map_1.ValueMap.fromObject({
                    maxValue: max,
                    minValue: min,
                }),
                textProps: value_map_1.ValueMap.fromObject({
                    draggingScale: util_1.getSuitableDraggingScale(c, value.rawValue),
                    formatter: formatter,
                }),
                value: value,
                viewProps: args.viewProps,
            });
        }
        return new number_text_1.NumberTextController(args.document, {
            baseStep: util_1.getBaseStep(c),
            parser: number_1.parseNumber,
            props: value_map_1.ValueMap.fromObject({
                draggingScale: util_1.getSuitableDraggingScale(c, value.rawValue),
                formatter: formatter,
            }),
            value: value,
            viewProps: args.viewProps,
        });
    },
};
