"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.ViewProps = void 0;
var class_name_1 = require("../view/class-name");
var reactive_1 = require("../view/reactive");
var reactive_2 = require("./reactive");
var value_map_1 = require("./value-map");
var className = class_name_1.ClassName('');
function valueToModifier(elem, modifier) {
    return reactive_1.valueToClassName(elem, className(undefined, modifier));
}
var ViewProps = /** @class */ (function (_super) {
    __extends(ViewProps, _super);
    function ViewProps(valueMap) {
        return _super.call(this, valueMap) || this;
    }
    ViewProps.create = function (opt_initialValue) {
        var _a, _b;
        var initialValue = opt_initialValue !== null && opt_initialValue !== void 0 ? opt_initialValue : {};
        var coreObj = {
            disabled: (_a = initialValue.disabled) !== null && _a !== void 0 ? _a : false,
            disposed: false,
            hidden: (_b = initialValue.hidden) !== null && _b !== void 0 ? _b : false,
        };
        var core = value_map_1.ValueMap.createCore(coreObj);
        return new ViewProps(core);
    };
    ViewProps.prototype.bindClassModifiers = function (elem) {
        reactive_2.bindValueMap(this, 'disabled', valueToModifier(elem, 'disabled'));
        reactive_2.bindValueMap(this, 'hidden', valueToModifier(elem, 'hidden'));
    };
    ViewProps.prototype.bindDisabled = function (target) {
        reactive_2.bindValueMap(this, 'disabled', function (disabled) {
            target.disabled = disabled;
        });
    };
    ViewProps.prototype.bindTabIndex = function (elem) {
        reactive_2.bindValueMap(this, 'disabled', function (disabled) {
            elem.tabIndex = disabled ? -1 : 0;
        });
    };
    ViewProps.prototype.handleDispose = function (callback) {
        this.value('disposed').emitter.on('change', function (disposed) {
            if (disposed) {
                callback();
            }
        });
    };
    return ViewProps;
}(value_map_1.ValueMap));
exports.ViewProps = ViewProps;
