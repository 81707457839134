"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.RangeConstraint = void 0;
var type_util_1 = require("../../misc/type-util");
/**
 * A number range constraint.
 */
var RangeConstraint = /** @class */ (function () {
    function RangeConstraint(config) {
        this.maxValue = config.max;
        this.minValue = config.min;
    }
    RangeConstraint.prototype.constrain = function (value) {
        var result = value;
        if (!type_util_1.isEmpty(this.minValue)) {
            result = Math.max(result, this.minValue);
        }
        if (!type_util_1.isEmpty(this.maxValue)) {
            result = Math.min(result, this.maxValue);
        }
        return result;
    };
    return RangeConstraint;
}());
exports.RangeConstraint = RangeConstraint;
