"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GraphCursor = void 0;
var emitter_1 = require("../../../common/model/emitter");
/**
 * @hidden
 */
var GraphCursor = /** @class */ (function () {
    function GraphCursor() {
        this.emitter = new emitter_1.Emitter();
        this.index_ = -1;
    }
    Object.defineProperty(GraphCursor.prototype, "index", {
        get: function () {
            return this.index_;
        },
        set: function (index) {
            var changed = this.index_ !== index;
            if (changed) {
                this.index_ = index;
                this.emitter.emit('change', {
                    index: index,
                    sender: this,
                });
            }
        },
        enumerable: false,
        configurable: true
    });
    return GraphCursor;
}());
exports.GraphCursor = GraphCursor;
