"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.warnDeprecation = void 0;
function warnDeprecation(info) {
    var _a;
    console.warn([
        info.name + " is deprecated.",
        info.alternative ? "use " + info.alternative + " instead." : '',
        (_a = info.postscript) !== null && _a !== void 0 ? _a : '',
    ].join(' '));
}
exports.warnDeprecation = warnDeprecation;
