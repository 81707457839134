"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StepConstraint = void 0;
/**
 * A number step range constraint.
 */
var StepConstraint = /** @class */ (function () {
    function StepConstraint(step) {
        this.step = step;
    }
    StepConstraint.prototype.constrain = function (value) {
        var r = value < 0
            ? -Math.round(-value / this.step)
            : Math.round(value / this.step);
        return r * this.step;
    };
    return StepConstraint;
}());
exports.StepConstraint = StepConstraint;
