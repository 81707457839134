"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ListConstraint = void 0;
/**
 * A list constranit.
 * @template T The type of the value.
 */
var ListConstraint = /** @class */ (function () {
    function ListConstraint(options) {
        this.options = options;
    }
    ListConstraint.prototype.constrain = function (value) {
        var opts = this.options;
        if (opts.length === 0) {
            return value;
        }
        var matched = opts.filter(function (item) {
            return item.value === value;
        }).length > 0;
        return matched ? value : opts[0].value;
    };
    return ListConstraint;
}());
exports.ListConstraint = ListConstraint;
