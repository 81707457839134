"use strict";
var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
Object.defineProperty(exports, "__esModule", { value: true });
exports.TabController = void 0;
var dom_util_1 = require("../../../common/dom-util");
var values_1 = require("../../../common/model/values");
var rack_like_1 = require("../../common/controller/rack-like");
var nested_ordered_set_1 = require("../../common/model/nested-ordered-set");
var rack_1 = require("../../rack/controller/rack");
var tab_1 = require("../view/tab");
var TabController = /** @class */ (function (_super) {
    __extends(TabController, _super);
    function TabController(doc, config) {
        var _this = this;
        var cr = new rack_1.RackController(doc, {
            blade: config.blade,
            viewProps: config.viewProps,
        });
        var empty = values_1.createValue(true);
        _this = _super.call(this, {
            blade: config.blade,
            rackController: cr,
            view: new tab_1.TabView(doc, {
                contentsElement: cr.view.element,
                empty: empty,
                viewProps: config.viewProps,
            }),
        }) || this;
        _this.onPageAdd_ = _this.onPageAdd_.bind(_this);
        _this.onPageRemove_ = _this.onPageRemove_.bind(_this);
        _this.onPageSelectedChange_ = _this.onPageSelectedChange_.bind(_this);
        _this.pageSet_ = new nested_ordered_set_1.NestedOrderedSet(function () { return null; });
        _this.pageSet_.emitter.on('add', _this.onPageAdd_);
        _this.pageSet_.emitter.on('remove', _this.onPageRemove_);
        _this.empty_ = empty;
        _this.applyPages_();
        return _this;
    }
    Object.defineProperty(TabController.prototype, "pageSet", {
        get: function () {
            return this.pageSet_;
        },
        enumerable: false,
        configurable: true
    });
    TabController.prototype.add = function (pc, opt_index) {
        this.pageSet_.add(pc, opt_index !== null && opt_index !== void 0 ? opt_index : this.pageSet_.items.length);
    };
    TabController.prototype.remove = function (index) {
        this.pageSet_.remove(this.pageSet_.items[index]);
    };
    TabController.prototype.applyPages_ = function () {
        this.keepSelection_();
        this.empty_.rawValue = this.pageSet_.items.length === 0;
    };
    TabController.prototype.onPageAdd_ = function (ev) {
        var pc = ev.item;
        dom_util_1.insertElementAt(this.view.itemsElement, pc.itemController.view.element, ev.index);
        this.rackController.rack.add(pc.contentController, ev.index);
        pc.props.value('selected').emitter.on('change', this.onPageSelectedChange_);
        this.applyPages_();
    };
    TabController.prototype.onPageRemove_ = function (ev) {
        var pc = ev.item;
        dom_util_1.removeElement(pc.itemController.view.element);
        this.rackController.rack.remove(pc.contentController);
        pc.props
            .value('selected')
            .emitter.off('change', this.onPageSelectedChange_);
        this.applyPages_();
    };
    TabController.prototype.keepSelection_ = function () {
        if (this.pageSet_.items.length === 0) {
            return;
        }
        var firstSelIndex = this.pageSet_.items.findIndex(function (pc) {
            return pc.props.get('selected');
        });
        if (firstSelIndex < 0) {
            this.pageSet_.items.forEach(function (pc, i) {
                pc.props.set('selected', i === 0);
            });
        }
        else {
            this.pageSet_.items.forEach(function (pc, i) {
                pc.props.set('selected', i === firstSelIndex);
            });
        }
    };
    TabController.prototype.onPageSelectedChange_ = function (ev) {
        if (ev.rawValue) {
            var index_1 = this.pageSet_.items.findIndex(function (pc) { return pc.props.value('selected') === ev.sender; });
            this.pageSet_.items.forEach(function (pc, i) {
                pc.props.set('selected', i === index_1);
            });
        }
        else {
            this.keepSelection_();
        }
    };
    return TabController;
}(rack_like_1.RackLikeController));
exports.TabController = TabController;
