"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.deepEqualsArray = exports.isEmpty = exports.forceCast = void 0;
function forceCast(v) {
    return v;
}
exports.forceCast = forceCast;
function isEmpty(value) {
    return value === null || value === undefined;
}
exports.isEmpty = isEmpty;
function deepEqualsArray(a1, a2) {
    if (a1.length !== a2.length) {
        return false;
    }
    for (var i = 0; i < a1.length; i++) {
        if (a1[i] !== a2[i]) {
            return false;
        }
    }
    return true;
}
exports.deepEqualsArray = deepEqualsArray;
