"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.createColorObjectWriter = exports.writeRgbColorObject = exports.writeRgbaColorObject = exports.createColorNumberWriter = exports.createColorStringWriter = void 0;
var primitive_1 = require("../../../common/primitive");
var color_number_1 = require("../converter/color-number");
var color_string_1 = require("../converter/color-string");
function createColorStringWriter(notation) {
    var stringify = color_string_1.getColorStringifier(notation);
    return function (target, value) {
        primitive_1.writePrimitive(target, stringify(value));
    };
}
exports.createColorStringWriter = createColorStringWriter;
function createColorNumberWriter(supportsAlpha) {
    var colorToNumber = supportsAlpha ? color_number_1.colorToRgbaNumber : color_number_1.colorToRgbNumber;
    return function (target, value) {
        primitive_1.writePrimitive(target, colorToNumber(value));
    };
}
exports.createColorNumberWriter = createColorNumberWriter;
function writeRgbaColorObject(target, value) {
    var obj = value.toRgbaObject();
    target.writeProperty('r', obj.r);
    target.writeProperty('g', obj.g);
    target.writeProperty('b', obj.b);
    target.writeProperty('a', obj.a);
}
exports.writeRgbaColorObject = writeRgbaColorObject;
function writeRgbColorObject(target, value) {
    var obj = value.toRgbaObject();
    target.writeProperty('r', obj.r);
    target.writeProperty('g', obj.g);
    target.writeProperty('b', obj.b);
}
exports.writeRgbColorObject = writeRgbColorObject;
function createColorObjectWriter(supportsAlpha) {
    return supportsAlpha ? writeRgbaColorObject : writeRgbColorObject;
}
exports.createColorObjectWriter = createColorObjectWriter;
