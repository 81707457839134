"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBaseStepForColor = exports.parseColorInputParams = void 0;
var params_parsers_1 = require("../../common/params-parsers");
var util_1 = require("../../common/util");
function parseColorInputParams(params) {
    var p = params_parsers_1.ParamsParsers;
    return params_parsers_1.parseParams(params, {
        alpha: p.optional.boolean,
        expanded: p.optional.boolean,
        picker: p.optional.custom(util_1.parsePickerLayout),
    });
}
exports.parseColorInputParams = parseColorInputParams;
/**
 * @hidden
 */
function getBaseStepForColor(forAlpha) {
    return forAlpha ? 0.1 : 1;
}
exports.getBaseStepForColor = getBaseStepForColor;
