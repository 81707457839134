"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getColorStringifier = exports.colorToFunctionalHslaString = exports.colorToFunctionalHslString = exports.colorToFunctionalRgbaString = exports.colorToFunctionalRgbString = exports.colorToHexRgbaString = exports.colorToHexRgbString = exports.colorFromString = exports.hasAlphaComponent = exports.CompositeColorParser = exports.getColorNotation = void 0;
var number_1 = require("../../../common/converter/number");
var percentage_1 = require("../../../common/converter/percentage");
var number_util_1 = require("../../../common/number-util");
var color_1 = require("../model/color");
var color_model_1 = require("../model/color-model");
function parseCssNumberOrPercentage(text, maxValue) {
    var m = text.match(/^(.+)%$/);
    if (!m) {
        return Math.min(parseFloat(text), maxValue);
    }
    return Math.min(parseFloat(m[1]) * 0.01 * maxValue, maxValue);
}
var ANGLE_TO_DEG_MAP = {
    deg: function (angle) { return angle; },
    grad: function (angle) { return (angle * 360) / 400; },
    rad: function (angle) { return (angle * 360) / (2 * Math.PI); },
    turn: function (angle) { return angle * 360; },
};
function parseCssNumberOrAngle(text) {
    var m = text.match(/^([0-9.]+?)(deg|grad|rad|turn)$/);
    if (!m) {
        return parseFloat(text);
    }
    var angle = parseFloat(m[1]);
    var unit = m[2];
    return ANGLE_TO_DEG_MAP[unit](angle);
}
var NOTATION_TO_PARSER_MAP = {
    'func.rgb': function (text) {
        var m = text.match(/^rgb\(\s*([0-9A-Fa-f.]+%?)\s*,\s*([0-9A-Fa-f.]+%?)\s*,\s*([0-9A-Fa-f.]+%?)\s*\)$/);
        if (!m) {
            return null;
        }
        var comps = [
            parseCssNumberOrPercentage(m[1], 255),
            parseCssNumberOrPercentage(m[2], 255),
            parseCssNumberOrPercentage(m[3], 255),
        ];
        if (isNaN(comps[0]) || isNaN(comps[1]) || isNaN(comps[2])) {
            return null;
        }
        return new color_1.Color(comps, 'rgb');
    },
    'func.rgba': function (text) {
        var m = text.match(/^rgba\(\s*([0-9A-Fa-f.]+%?)\s*,\s*([0-9A-Fa-f.]+%?)\s*,\s*([0-9A-Fa-f.]+%?)\s*,\s*([0-9A-Fa-f.]+%?)\s*\)$/);
        if (!m) {
            return null;
        }
        var comps = [
            parseCssNumberOrPercentage(m[1], 255),
            parseCssNumberOrPercentage(m[2], 255),
            parseCssNumberOrPercentage(m[3], 255),
            parseCssNumberOrPercentage(m[4], 1),
        ];
        if (isNaN(comps[0]) ||
            isNaN(comps[1]) ||
            isNaN(comps[2]) ||
            isNaN(comps[3])) {
            return null;
        }
        return new color_1.Color(comps, 'rgb');
    },
    'func.hsl': function (text) {
        var m = text.match(/^hsl\(\s*([0-9A-Fa-f.]+(?:deg|grad|rad|turn)?)\s*,\s*([0-9A-Fa-f.]+%?)\s*,\s*([0-9A-Fa-f.]+%?)\s*\)$/);
        if (!m) {
            return null;
        }
        var comps = [
            parseCssNumberOrAngle(m[1]),
            parseCssNumberOrPercentage(m[2], 100),
            parseCssNumberOrPercentage(m[3], 100),
        ];
        if (isNaN(comps[0]) || isNaN(comps[1]) || isNaN(comps[2])) {
            return null;
        }
        return new color_1.Color(comps, 'hsl');
    },
    'func.hsla': function (text) {
        var m = text.match(/^hsla\(\s*([0-9A-Fa-f.]+(?:deg|grad|rad|turn)?)\s*,\s*([0-9A-Fa-f.]+%?)\s*,\s*([0-9A-Fa-f.]+%?)\s*,\s*([0-9A-Fa-f.]+%?)\s*\)$/);
        if (!m) {
            return null;
        }
        var comps = [
            parseCssNumberOrAngle(m[1]),
            parseCssNumberOrPercentage(m[2], 100),
            parseCssNumberOrPercentage(m[3], 100),
            parseCssNumberOrPercentage(m[4], 1),
        ];
        if (isNaN(comps[0]) ||
            isNaN(comps[1]) ||
            isNaN(comps[2]) ||
            isNaN(comps[3])) {
            return null;
        }
        return new color_1.Color(comps, 'hsl');
    },
    'hex.rgb': function (text) {
        var mRgb = text.match(/^#([0-9A-Fa-f])([0-9A-Fa-f])([0-9A-Fa-f])$/);
        if (mRgb) {
            return new color_1.Color([
                parseInt(mRgb[1] + mRgb[1], 16),
                parseInt(mRgb[2] + mRgb[2], 16),
                parseInt(mRgb[3] + mRgb[3], 16),
            ], 'rgb');
        }
        var mRrggbb = text.match(/^(?:#|0x)([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})$/);
        if (mRrggbb) {
            return new color_1.Color([
                parseInt(mRrggbb[1], 16),
                parseInt(mRrggbb[2], 16),
                parseInt(mRrggbb[3], 16),
            ], 'rgb');
        }
        return null;
    },
    'hex.rgba': function (text) {
        var mRgb = text.match(/^#?([0-9A-Fa-f])([0-9A-Fa-f])([0-9A-Fa-f])([0-9A-Fa-f])$/);
        if (mRgb) {
            return new color_1.Color([
                parseInt(mRgb[1] + mRgb[1], 16),
                parseInt(mRgb[2] + mRgb[2], 16),
                parseInt(mRgb[3] + mRgb[3], 16),
                number_util_1.mapRange(parseInt(mRgb[4] + mRgb[4], 16), 0, 255, 0, 1),
            ], 'rgb');
        }
        var mRrggbb = text.match(/^(?:#|0x)?([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})([0-9A-Fa-f]{2})$/);
        if (mRrggbb) {
            return new color_1.Color([
                parseInt(mRrggbb[1], 16),
                parseInt(mRrggbb[2], 16),
                parseInt(mRrggbb[3], 16),
                number_util_1.mapRange(parseInt(mRrggbb[4], 16), 0, 255, 0, 1),
            ], 'rgb');
        }
        return null;
    },
};
/**
 * @hidden
 */
function getColorNotation(text) {
    var notations = Object.keys(NOTATION_TO_PARSER_MAP);
    return notations.reduce(function (result, notation) {
        if (result) {
            return result;
        }
        var subparser = NOTATION_TO_PARSER_MAP[notation];
        return subparser(text) ? notation : null;
    }, null);
}
exports.getColorNotation = getColorNotation;
/**
 * @hidden
 */
var CompositeColorParser = function (text) {
    var notation = getColorNotation(text);
    return notation ? NOTATION_TO_PARSER_MAP[notation](text) : null;
};
exports.CompositeColorParser = CompositeColorParser;
function hasAlphaComponent(notation) {
    return (notation === 'func.hsla' ||
        notation === 'func.rgba' ||
        notation === 'hex.rgba');
}
exports.hasAlphaComponent = hasAlphaComponent;
/**
 * @hidden
 */
function colorFromString(value) {
    if (typeof value === 'string') {
        var cv = exports.CompositeColorParser(value);
        if (cv) {
            return cv;
        }
    }
    return color_1.Color.black();
}
exports.colorFromString = colorFromString;
function zerofill(comp) {
    var hex = number_util_1.constrainRange(Math.floor(comp), 0, 255).toString(16);
    return hex.length === 1 ? "0" + hex : hex;
}
/**
 * @hidden
 */
function colorToHexRgbString(value, prefix) {
    if (prefix === void 0) { prefix = '#'; }
    var hexes = color_model_1.removeAlphaComponent(value.getComponents('rgb'))
        .map(zerofill)
        .join('');
    return "" + prefix + hexes;
}
exports.colorToHexRgbString = colorToHexRgbString;
/**
 * @hidden
 */
function colorToHexRgbaString(value, prefix) {
    if (prefix === void 0) { prefix = '#'; }
    var rgbaComps = value.getComponents('rgb');
    var hexes = [rgbaComps[0], rgbaComps[1], rgbaComps[2], rgbaComps[3] * 255]
        .map(zerofill)
        .join('');
    return "" + prefix + hexes;
}
exports.colorToHexRgbaString = colorToHexRgbaString;
/**
 * @hidden
 */
function colorToFunctionalRgbString(value) {
    var formatter = number_1.createNumberFormatter(0);
    var comps = color_model_1.removeAlphaComponent(value.getComponents('rgb')).map(function (comp) {
        return formatter(comp);
    });
    return "rgb(" + comps.join(', ') + ")";
}
exports.colorToFunctionalRgbString = colorToFunctionalRgbString;
/**
 * @hidden
 */
function colorToFunctionalRgbaString(value) {
    var aFormatter = number_1.createNumberFormatter(2);
    var rgbFormatter = number_1.createNumberFormatter(0);
    var comps = value.getComponents('rgb').map(function (comp, index) {
        var formatter = index === 3 ? aFormatter : rgbFormatter;
        return formatter(comp);
    });
    return "rgba(" + comps.join(', ') + ")";
}
exports.colorToFunctionalRgbaString = colorToFunctionalRgbaString;
/**
 * @hidden
 */
function colorToFunctionalHslString(value) {
    var formatters = [
        number_1.createNumberFormatter(0),
        percentage_1.formatPercentage,
        percentage_1.formatPercentage,
    ];
    var comps = color_model_1.removeAlphaComponent(value.getComponents('hsl')).map(function (comp, index) { return formatters[index](comp); });
    return "hsl(" + comps.join(', ') + ")";
}
exports.colorToFunctionalHslString = colorToFunctionalHslString;
/**
 * @hidden
 */
function colorToFunctionalHslaString(value) {
    var formatters = [
        number_1.createNumberFormatter(0),
        percentage_1.formatPercentage,
        percentage_1.formatPercentage,
        number_1.createNumberFormatter(2),
    ];
    var comps = value
        .getComponents('hsl')
        .map(function (comp, index) { return formatters[index](comp); });
    return "hsla(" + comps.join(', ') + ")";
}
exports.colorToFunctionalHslaString = colorToFunctionalHslaString;
var NOTATION_TO_STRINGIFIER_MAP = {
    'func.hsl': colorToFunctionalHslString,
    'func.hsla': colorToFunctionalHslaString,
    'func.rgb': colorToFunctionalRgbString,
    'func.rgba': colorToFunctionalRgbaString,
    'hex.rgb': colorToHexRgbString,
    'hex.rgba': colorToHexRgbaString,
};
function getColorStringifier(notation) {
    return NOTATION_TO_STRINGIFIER_MAP[notation];
}
exports.getColorStringifier = getColorStringifier;
