"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.formatString = exports.stringFromUnknown = void 0;
/**
 * @hidden
 */
function stringFromUnknown(value) {
    return String(value);
}
exports.stringFromUnknown = stringFromUnknown;
/**
 * @hidden
 */
function formatString(value) {
    return value;
}
exports.formatString = formatString;
