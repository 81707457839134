"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ObjectColorInputPlugin = void 0;
var color_1 = require("./controller/color");
var color_number_1 = require("./converter/color-number");
var color_string_1 = require("./converter/color-string");
var writer_1 = require("./converter/writer");
var color_2 = require("./model/color");
var util_1 = require("./util");
function shouldSupportAlpha(initialValue) {
    return color_2.Color.isRgbaColorObject(initialValue);
}
/**
 * @hidden
 */
exports.ObjectColorInputPlugin = {
    id: 'input-color-object',
    type: 'input',
    accept: function (value, params) {
        if (!color_2.Color.isColorObject(value)) {
            return null;
        }
        var result = util_1.parseColorInputParams(params);
        return result
            ? {
                initialValue: value,
                params: result,
            }
            : null;
    },
    binding: {
        reader: function (_args) { return color_number_1.colorFromObject; },
        equals: color_2.Color.equals,
        writer: function (args) {
            return writer_1.createColorObjectWriter(shouldSupportAlpha(args.initialValue));
        },
    },
    controller: function (args) {
        var supportsAlpha = color_2.Color.isRgbaColorObject(args.initialValue);
        var expanded = 'expanded' in args.params ? args.params.expanded : undefined;
        var picker = 'picker' in args.params ? args.params.picker : undefined;
        var formatter = supportsAlpha
            ? color_string_1.colorToHexRgbaString
            : color_string_1.colorToHexRgbString;
        return new color_1.ColorController(args.document, {
            expanded: expanded !== null && expanded !== void 0 ? expanded : false,
            formatter: formatter,
            parser: color_string_1.CompositeColorParser,
            pickerLayout: picker !== null && picker !== void 0 ? picker : 'popup',
            supportsAlpha: supportsAlpha,
            value: args.value,
            viewProps: args.viewProps,
        });
    },
};
