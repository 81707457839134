"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Point2dInputPlugin = exports.getSuitableMaxValue = void 0;
var composite_1 = require("../../common/constraint/composite");
var range_1 = require("../../common/constraint/range");
var step_1 = require("../../common/constraint/step");
var number_1 = require("../../common/converter/number");
var value_map_1 = require("../../common/model/value-map");
var params_parsers_1 = require("../../common/params-parsers");
var tp_error_1 = require("../../common/tp-error");
var util_1 = require("../../common/util");
var type_util_1 = require("../../misc/type-util");
var point_nd_1 = require("../common/constraint/point-nd");
var point_2d_1 = require("./controller/point-2d");
var point_2d_2 = require("./converter/point-2d");
var point_2d_3 = require("./model/point-2d");
function createDimensionConstraint(params) {
    if (!params) {
        return undefined;
    }
    var constraints = [];
    if (!type_util_1.isEmpty(params.step)) {
        constraints.push(new step_1.StepConstraint(params.step));
    }
    if (!type_util_1.isEmpty(params.max) || !type_util_1.isEmpty(params.min)) {
        constraints.push(new range_1.RangeConstraint({
            max: params.max,
            min: params.min,
        }));
    }
    return new composite_1.CompositeConstraint(constraints);
}
function createConstraint(params) {
    return new point_nd_1.PointNdConstraint({
        assembly: point_2d_3.Point2dAssembly,
        components: [
            createDimensionConstraint('x' in params ? params.x : undefined),
            createDimensionConstraint('y' in params ? params.y : undefined),
        ],
    });
}
function getSuitableMaxDimensionValue(constraint, rawValue) {
    var rc = constraint && composite_1.findConstraint(constraint, range_1.RangeConstraint);
    if (rc) {
        return Math.max(Math.abs(rc.minValue || 0), Math.abs(rc.maxValue || 0));
    }
    var step = util_1.getBaseStep(constraint);
    return Math.max(Math.abs(step) * 10, Math.abs(rawValue) * 10);
}
/**
 * @hidden
 */
function getSuitableMaxValue(initialValue, constraint) {
    var xc = constraint instanceof point_nd_1.PointNdConstraint
        ? constraint.components[0]
        : undefined;
    var yc = constraint instanceof point_nd_1.PointNdConstraint
        ? constraint.components[1]
        : undefined;
    var xr = getSuitableMaxDimensionValue(xc, initialValue.x);
    var yr = getSuitableMaxDimensionValue(yc, initialValue.y);
    return Math.max(xr, yr);
}
exports.getSuitableMaxValue = getSuitableMaxValue;
function createAxis(initialValue, constraint) {
    return {
        baseStep: util_1.getBaseStep(constraint),
        constraint: constraint,
        textProps: value_map_1.ValueMap.fromObject({
            draggingScale: util_1.getSuitableDraggingScale(constraint, initialValue),
            formatter: number_1.createNumberFormatter(util_1.getSuitableDecimalDigits(constraint, initialValue)),
        }),
    };
}
function shouldInvertY(params) {
    if (!('y' in params)) {
        return false;
    }
    var yParams = params.y;
    if (!yParams) {
        return false;
    }
    return 'inverted' in yParams ? !!yParams.inverted : false;
}
/**
 * @hidden
 */
exports.Point2dInputPlugin = {
    id: 'input-point2d',
    type: 'input',
    accept: function (value, params) {
        if (!point_2d_3.Point2d.isObject(value)) {
            return null;
        }
        var p = params_parsers_1.ParamsParsers;
        var result = params_parsers_1.parseParams(params, {
            expanded: p.optional.boolean,
            picker: p.optional.custom(util_1.parsePickerLayout),
            x: p.optional.custom(util_1.parsePointDimensionParams),
            y: p.optional.object({
                inverted: p.optional.boolean,
                max: p.optional.number,
                min: p.optional.number,
                step: p.optional.number,
            }),
        });
        return result
            ? {
                initialValue: value,
                params: result,
            }
            : null;
    },
    binding: {
        reader: function (_args) { return point_2d_2.point2dFromUnknown; },
        constraint: function (args) { return createConstraint(args.params); },
        equals: point_2d_3.Point2d.equals,
        writer: function (_args) { return point_2d_2.writePoint2d; },
    },
    controller: function (args) {
        var doc = args.document;
        var value = args.value;
        var c = args.constraint;
        if (!(c instanceof point_nd_1.PointNdConstraint)) {
            throw tp_error_1.TpError.shouldNeverHappen();
        }
        var expanded = 'expanded' in args.params ? args.params.expanded : undefined;
        var picker = 'picker' in args.params ? args.params.picker : undefined;
        return new point_2d_1.Point2dController(doc, {
            axes: [
                createAxis(value.rawValue.x, c.components[0]),
                createAxis(value.rawValue.y, c.components[1]),
            ],
            expanded: expanded !== null && expanded !== void 0 ? expanded : false,
            invertsY: shouldInvertY(args.params),
            maxValue: getSuitableMaxValue(value.rawValue, c),
            parser: number_1.parseNumber,
            pickerLayout: picker !== null && picker !== void 0 ? picker : 'popup',
            value: value,
            viewProps: args.viewProps,
        });
    },
};
