"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.StringColorInputPlugin = void 0;
var tp_error_1 = require("../../common/tp-error");
var color_1 = require("./controller/color");
var color_string_1 = require("./converter/color-string");
var writer_1 = require("./converter/writer");
var color_2 = require("./model/color");
var util_1 = require("./util");
/**
 * @hidden
 */
exports.StringColorInputPlugin = {
    id: 'input-color-string',
    type: 'input',
    accept: function (value, params) {
        if (typeof value !== 'string') {
            return null;
        }
        if ('view' in params && params.view === 'text') {
            return null;
        }
        var notation = color_string_1.getColorNotation(value);
        if (!notation) {
            return null;
        }
        var result = util_1.parseColorInputParams(params);
        return result
            ? {
                initialValue: value,
                params: result,
            }
            : null;
    },
    binding: {
        reader: function (_args) { return color_string_1.colorFromString; },
        equals: color_2.Color.equals,
        writer: function (args) {
            var notation = color_string_1.getColorNotation(args.initialValue);
            if (!notation) {
                throw tp_error_1.TpError.shouldNeverHappen();
            }
            return writer_1.createColorStringWriter(notation);
        },
    },
    controller: function (args) {
        var notation = color_string_1.getColorNotation(args.initialValue);
        if (!notation) {
            throw tp_error_1.TpError.shouldNeverHappen();
        }
        var stringifier = color_string_1.getColorStringifier(notation);
        var expanded = 'expanded' in args.params ? args.params.expanded : undefined;
        var picker = 'picker' in args.params ? args.params.picker : undefined;
        return new color_1.ColorController(args.document, {
            expanded: expanded !== null && expanded !== void 0 ? expanded : false,
            formatter: stringifier,
            parser: color_string_1.CompositeColorParser,
            pickerLayout: picker !== null && picker !== void 0 ? picker : 'popup',
            supportsAlpha: color_string_1.hasAlphaComponent(notation),
            value: args.value,
            viewProps: args.viewProps,
        });
    },
};
